
import Base from './loginBase'
import AuthenticationRepository from "@/api/AuthenticationRepository";

export default class GoogleLogin extends Base {
  static SCRIPT_ID = 'google-sdk'
  static SCRIPT_URL = 'https://accounts.google.com/gsi/client'
  apiReady = false
  handlers = []

  constructor (app) {
    super(app)
    this.onLogin = this.onLogin.bind(this)
    this.onFailure = this.onFailure.bind(this)
    this.onScriptLoad = this.onScriptLoad.bind(this)
    this.onApiReady = this.onApiReady.bind(this)
    this.initialize()
  }

  initialize () {
    this.loadScript(GoogleLogin.SCRIPT_ID, GoogleLogin.SCRIPT_URL, this.onScriptLoad)
  }

  destroy () {
    this.deleteScript(GoogleLogin.SCRIPT_ID)
    try {
      delete window.google.accounts
    } catch (err) {
      // Not always can be done. Avoid console error
    }
  }

  onScriptLoad () {
    if (!window.google?.accounts) {
      console.error('Google accounts not loaded');
      return false;
    }

    const config = {
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      callback: this.onLogin
    }
    window.google.accounts.id.initialize(config)

    this.onApiReady()
  }

  onApiReady () {
    this.handlers.forEach(h => this.addHandler(h))
    this.apiReady = true
  }
  attachClickHandler (element) {
    if (this.apiReady) {
      this.addHandler(element)
      return
    }
    this.handlers.push(element)
  }
  addHandler (container) {
    if (!window.google?.accounts) {
      return
    }
    window.google.accounts.id.renderButton(container, { type: 'standard', size: 'large', width: 420 })
  }

  onFailure (err) {
    if (err?.error === 'popup_closed_by_user') {
      return
    }
    super.onFailure(err)
  }

  login () {
    // Nothing here. Google uses accounts.id.renderButton to manage login
  }

  async onLogin (response) {
    try {
      const apiResponse = await AuthenticationRepository.googleLogin({
        license: this.getLicense(),
        token: response.credential
      });
      await this.app.$store.dispatch('session/tokenLogin', apiResponse.data);
    } catch (err) {
      this.onFailure(err)
      return false;
    }
    window.dispatchEvent(new CustomEvent('bambuyo:close-all')); // close all modals
    if (this.app.$store.getters['basket/orderDetails'].length > 0) {
      window.dispatchEvent(new CustomEvent('bambuyo:basket')); // open basket modal if there are products
    }
    return true;
  }

  getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
  }

}
